/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TopicTaskGroup,
    TopicTaskGroupFromJSON,
    TopicTaskGroupFromJSONTyped,
    TopicTaskGroupToJSON,
} from './TopicTaskGroup';

/**
 * 
 * @export
 * @interface Topic
 */
export interface Topic {
    /**
     * 
     * @type {number}
     * @memberof Topic
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    subtitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    summary?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Topic
     */
    monthNumber?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Topic
     */
    progressPercentage?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Topic
     */
    isOngoing?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Topic
     */
    startDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Topic
     */
    endDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    dateHeader?: string | null;
    /**
     * 
     * @type {Array<TopicTaskGroup>}
     * @memberof Topic
     */
    taskGroups?: Array<TopicTaskGroup> | null;
}

export function TopicFromJSON(json: any): Topic {
    return TopicFromJSONTyped(json, false);
}

export function TopicFromJSONTyped(json: any, ignoreDiscriminator: boolean): Topic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'subtitle': !exists(json, 'subtitle') ? undefined : json['subtitle'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'monthNumber': !exists(json, 'monthNumber') ? undefined : json['monthNumber'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'progressPercentage': !exists(json, 'progressPercentage') ? undefined : json['progressPercentage'],
        'isOngoing': !exists(json, 'isOngoing') ? undefined : json['isOngoing'],
        'startDate': !exists(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
        'dateHeader': !exists(json, 'dateHeader') ? undefined : json['dateHeader'],
        'taskGroups': !exists(json, 'taskGroups') ? undefined : (json['taskGroups'] === null ? null : (json['taskGroups'] as Array<any>).map(TopicTaskGroupFromJSON)),
    };
}

export function TopicToJSON(value?: Topic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'subtitle': value.subtitle,
        'summary': value.summary,
        'monthNumber': value.monthNumber,
        'type': value.type,
        'progressPercentage': value.progressPercentage,
        'isOngoing': value.isOngoing,
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
        'dateHeader': value.dateHeader,
        'taskGroups': value.taskGroups === undefined ? undefined : (value.taskGroups === null ? null : (value.taskGroups as Array<any>).map(TopicTaskGroupToJSON)),
    };
}

