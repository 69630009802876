/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WeekResponse,
    WeekResponseFromJSON,
    WeekResponseFromJSONTyped,
    WeekResponseToJSON,
} from './WeekResponse';

/**
 * 
 * @export
 * @interface GetTopicByIdResponse
 */
export interface GetTopicByIdResponse {
    /**
     * 
     * @type {number}
     * @memberof GetTopicByIdResponse
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof GetTopicByIdResponse
     */
    endDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof GetTopicByIdResponse
     */
    startDate?: Date | null;
    /**
     * 
     * @type {Array<WeekResponse>}
     * @memberof GetTopicByIdResponse
     */
    taskGroups?: Array<WeekResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof GetTopicByIdResponse
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTopicByIdResponse
     */
    subtitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTopicByIdResponse
     */
    summary?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTopicByIdResponse
     */
    type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetTopicByIdResponse
     */
    monthNumber?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetTopicByIdResponse
     */
    trackId?: number;
}

export function GetTopicByIdResponseFromJSON(json: any): GetTopicByIdResponse {
    return GetTopicByIdResponseFromJSONTyped(json, false);
}

export function GetTopicByIdResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTopicByIdResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
        'startDate': !exists(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'taskGroups': !exists(json, 'taskGroups') ? undefined : (json['taskGroups'] === null ? null : (json['taskGroups'] as Array<any>).map(WeekResponseFromJSON)),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'subtitle': !exists(json, 'subtitle') ? undefined : json['subtitle'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'monthNumber': !exists(json, 'monthNumber') ? undefined : json['monthNumber'],
        'trackId': !exists(json, 'trackId') ? undefined : json['trackId'],
    };
}

export function GetTopicByIdResponseToJSON(value?: GetTopicByIdResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString()),
        'taskGroups': value.taskGroups === undefined ? undefined : (value.taskGroups === null ? null : (value.taskGroups as Array<any>).map(WeekResponseToJSON)),
        'title': value.title,
        'subtitle': value.subtitle,
        'summary': value.summary,
        'type': value.type,
        'monthNumber': value.monthNumber,
        'trackId': value.trackId,
    };
}

